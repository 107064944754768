export enum PageType {
    HOME = 'Home',
    LISTING = 'Listing',
    PDP = 'PDP',
    LOGIN = 'Login',
    SIGN_UP_ECOM = 'Sign-Up Ecom',
    BLOG = 'Blog',
    SEARCH_RESULTS = 'Search Results',
    CART = 'Cart',
    CHECKOUT = 'Checkout',
    TYP = 'TYP',
    MY_ACCOUNT = 'MyAccount',
    MY_PROFILE = 'MyProfile',
    LOYALTY = 'Loyalty',
    WISHLIST = 'Wishlist',
    OTHER = 'Other',
    PAGE_TYPE_NOT_LOADED = 'PageTypeNotLoaded',
}

export enum GtmEvents {
    SELECT_ITEM = 'select_item_new',
    VIEW_CART = 'view_cart_new',
    ADD_TO_CART = 'add_to_cart_new',
    REMOVE_FROM_CART = 'remove_from_cart_new',
    VIEW_ITEM_LIST = 'view_item_list_new',
    VIEW_ITEM = 'view_item_new',
    BEGIN_CHECKOUT = 'begin_checkout_new',
    PURCHASE = 'purchase_new',
    LOGIN = 'login_new',
    USER_DATA = 'user_data_new',
    ADD_TO_WISHLIST = 'add_to_wishlist_new',
    ADD_SHIPPING_INFO = 'add_shipping_info_new',
    ADD_PAYMENT_INFO = 'add_payment_info_new',
    CLICK_MENU = 'click_menu_new',
    CLICK_FILTER = 'click_filter_new',
    VIEW_SEARCH_RESULT = 'view_search_results_new',
    ADD_VENCHI_MIX = 'add_venchi_mix_new'
}

export enum CheckoutType {
    GUEST_CHECKOUT = 'guest_checkout',
    CLASSIC_CHECKOUT = 'classic_checkout'
}

export enum LoginType {
    GOOGLE = 'google',
    FACEBOOK = 'fb'
}

export enum LoginFromType {
    CHECKOUT = 'checkout',
    LOGIN = 'login'
}

export enum LoginState {
    LOGGED_USER = 'logged_user',
    GUEST = 'guest'
}

export enum SessionStorageKey {
    PREV_LOGIN_STATE = 'prev_login_state',
    LOGIN_FROM = 'login_from'
}
